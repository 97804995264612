* {
  margin: 0;
  padding: 0;
  /* min-height: 200vh; */
}

html,
body {
  overflow: hidden;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}
